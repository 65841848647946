.dual-color-background {
    width: 100%;
    height: 100vh; /* Adjust as needed */
    background: linear-gradient(to left, #5479FA 50%, #E8ECF5 50%); /* Blue for the left 50%, White for the right 50% */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .dual-color-background {
        background: linear-gradient(to bottom, #5479FA 50%, #E8ECF5 50%); /* Blue for the top 50%, White for the bottom 50% */
    }
}
